import { MDBCol } from 'mdbreact';
import MemberCard from './memberrcard';
import React from 'react';

const MembersList = (props) => {

    const members = props.members

    let numRows
    (members != null) ? numRows = members.length : numRows = 4
    let xsVl; let mdVl; let lgVl
  
    if (numRows === 1) {
        xsVl = `12`; mdVl = `12`; lgVl = `12`
    }   
    else {
        if (numRows === 2) {
            xsVl = `12`; mdVl = `6`; lgVl = `6`
        } 
        else {
            if (numRows % 3 === 0) {
                xsVl = `12`; mdVl = `4`; lgVl = `4`
            }
            else {
                xsVl = `12`; mdVl = `6`; lgVl = `3`
            }
        }
    }

    return (
        <>
            {members.relationships.field_director.map((member, key) => {  
            let imageMember = '/images/avatars/noimage.png'
            if (member.relationships.field_member_photo) { 
                imageMember = member.relationships.field_member_photo.localFile.childImageSharp.director_photo.src
            }
            return  (
                <MDBCol key={key} xs={xsVl} md={mdVl} lg={lgVl} className={`col-${xsVl} px-1 py-0 m-0`}>
                    <MemberCard
                        image={imageMember}
                        name={member.field_title}
                        alias={member.field_sortalias}
                        role={member.field_sub_title}
                    />
                </MDBCol>
            )
            })}
        </>
    )
}

export default MembersList;