import { MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LinkComp = styled(Link)`
    color:#212529!important;
`

const MDBCardComp = styled(MDBCard)`
    background-color: #d4d5d6;
    height: 98%;
    div.Ripple-parent{ 
        text-align: center;
        min-height: 130px;
        line-height: 130px;
        img {
            margin: 10px auto;
            display: inline;
            border-radius: .25rem;
            box-shadow: 2px 2px #d4d5d6;
            margin-bottom: 20px;
            width: 100px;
            height: 144px;
        }
    }
    div.card-body {
        min-height: 8em;
        text-align: center;
        padding-left:4px;
        padding-right:4px;
        padding-top:0px;
        padding-bottom:4px;
        h2 {
            font-size:1.2rem;
            line-height: 1rem;
        }
        h5 {
            font-size: 1rem;
            line-height: 1rem;
            text-shadow: 2px 2px #d4d5d6;
        }
    }
`

const MemberCard = (props) => {
    const url = `/about-us/company-profile/leadership-team/${cleanUrl(props.alias)}`.toLowerCase()
    return (
        <LinkComp to={url} className='d-block my-1'>
            <MDBCardComp>
                {props.image 
                ? <MDBCardImage className="img-fluid" src={props.image} alt={props.name}  waves /> 
                : <div style={{height: "10px"}}></div>}
                <MDBCardBody>
                    <h2>{props.name}</h2>
                    <h5>{props.role}</h5>
                </MDBCardBody>
            </MDBCardComp>
        </LinkComp>
    )
}

export default MemberCard;

//  Function to set a good url
const cleanUrl = (string) => {
    string = string.replace(/\s/g, "-")
    return encodeURI(string)
}