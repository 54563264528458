import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../../components/menus/bcmenu';
import InlineBodyTransform from '../../../components/inlinebodytransform';
import Layout from '../../../components/layout';
import MembersList from '../../../components/aboutus/memberslist';
import React from 'react';
import SEO from '../../../components/seo';
import SideNavBar from '../../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const members = data.nodeLeadershipTeam

  let backgroundImage = ''
  if (members.relationships.field_image_banner != null) {
    backgroundImage = members.relationships.field_image_banner.localFile.childImageSharp.boardofdirectors.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={members.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {members.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              <MDBRow center>
                <InlineBodyTransform bodyValue={members.body} />
                <MembersList members={members} />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
{
  nodeLeadershipTeam(id: {eq: "23dacdc0-dcc2-58b7-8378-b3d195b7b529"}) {
    id
    title
    path {
      alias
    }
    relationships {
      field_director {
        field_description {
          value
        }
        id
        field_title
        field_sub_title
        field_sortalias
        relationships {
          field_member_photo {
            localFile {
              childImageSharp {
                director_photo: resize(width: 100, height: 144) {
                  src
                }
              }
            }
          }
        }
      }
      field_image_banner {
        localFile {
          childImageSharp {
            boardofdirectors: original {
              src
            }
          }
        }
      }
    }
  }

  # Menu relation
  allMenuLinkContentMenuLinkContent {
    edges {
      node {
        title
        link {
          uri
        }
        menu_name
      }
    }
  }
}
`